import React from "react"

import "./static/styles/global.scss"
import { getFirebase } from "./src/services/firebase"
import { AppProvider } from "./src/context/AppContext"
import { AuthProvider } from "./src/context/AuthContext"
import PhoneAuthenticationProvider from "./src/context/PhoneAuthenticationContext"
import { DoctorEnrollmentProvider } from "./src/components/Enrollment/EnrollmentContext/DoctorEnrollmentContext"
import { EpharmacyProvider } from "./src/components/Epharmacy/EpharmacyContext/EpharmacyContext"
import { PatientEnrollmentProvider } from "./src/components/Enrollment/EnrollmentContext/PatientEnrollmentContext"

import "./src/services/i18n"

export const wrapRootElement = ({ element }) => {
  getFirebase()

  return (
    <AppProvider>
      <AuthProvider>
        <PhoneAuthenticationProvider>
          <DoctorEnrollmentProvider>
            <EpharmacyProvider>
              <PatientEnrollmentProvider>{element}</PatientEnrollmentProvider>
            </EpharmacyProvider>
          </DoctorEnrollmentProvider>
        </PhoneAuthenticationProvider>
      </AuthProvider>
    </AppProvider>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
